import CalendarIcon from 'assets/icons/calendar_icon.svg';
import NotificationIcon from 'assets/icons/notification_icon.svg';
import StrengthIcon from 'assets/icons/strength_icon.svg';

export const LIST_ITEMS = [
	{
		icon: CalendarIcon,
		label: 'functions.list.schedule'
	},
	{
		icon: StrengthIcon,
		label: 'functions.list.strength'
	},
	{
		icon: NotificationIcon,
		label: 'functions.list.notification'
	}
];
